<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <orisis-logo-full />
        </b-link>

        <b-card-title class="mb-1">
          Bienvenue sur ORISIS 👋 !
        </b-card-title>
        <b-card-text class="mb-2">
          Votre nouveau logiciel de <span>gestion d'entreprise</span> à haute performance.
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Adresse e-mail"
              class="mb-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="email@domaine.fr"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group class="mb-1"> 
              <div class="d-flex justify-content-between">
                <label for="password">Mot de passe</label>
                <b-link :to="'/forgot-password'">
                  <small>Mot de passe oublié ?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="userPassword"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Mot de passe"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group class="mb-1">
              <b-form-checkbox
                id="remember-me"
                v-model="userRemember"
                name="checkbox-1"
              >
                Mémoriser mes identifiants
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="validationForm"
            >
              Connexion
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Logiciel soumis à l'acceptation des </span>
          <a href="https://www.orisis.fr/mentions-legales" target="new">
            <span>CGU</span>
          </a>
        </b-card-text>

      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, configure, localize } from 'vee-validate';
import { required, email } from "@validations";
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
configure({
  generateMessage: localize('fr', {
    messages: {
        url: 'Ce champ doit être une URL',  
        required: 'Ce champ est requis',
        email: 'Ce champ doit être un email',
      
    },
  }),
});
localize('fr')


import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import OrisisLogoFull from "@core/layouts/components/LogoFull.vue";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    OrisisLogoFull,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      userPassword: '',
      userRemember:false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods:{
    validationForm() {
      var payload = {
        email: this.userEmail,
        password: this.userPassword,
        rememberMe: this.userRemember
      };
        var that = this;

      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.$store.dispatch('login', {payload:payload, that: that})
        }else{

        }
      });
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
